<template>
  <b-card title="Thông tin sản phẩm">
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Tên sản phẩm"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-input
              id="h-first-name"
              v-model="productData.name"
              placeholder="Bưởi hồng da xanh"
              :class="{ 'is-invalid' : isInvalid(productData.name)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Mô tả"
            label-cols-md="2"
            label-for="product-description"
          >
            <b-form-textarea
              id="product-description"
              v-model="productData.description"
              placeholder=""
              rows="5"
              :class="{ 'is-invalid' : isInvalid(productData.description)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Giá (VNĐ)"
            label-cols-md="4"
            label-for="h-price"
          >
            <b-form-input
              :disabled="productData.is_contact_price"
              id="h-price"
              v-model="productData.price"
              placeholder="100000"
              type="number"
              :class="{ 'is-invalid' : isInvalid(productData.price)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label=""
              label-cols-md="0"
              label-for="h-price-contact"
          >
            <b-form-checkbox
                id="h-price-contact"
                @change="changeContactPrice"
                v-model="productData.is_contact_price"
                name="checkbox-2"
            > Liên hệ </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Đơn vị"
              label-cols-md="2"
              label-for="h-unit"
          >
            <b-form-input
                :disabled="productData.is_contact_price"
                id="h-unit"
                v-model="productData.unit"
                placeholder="Kg/Túi/Nải/Quả"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Danh mục"
            label-cols-md="2"
            label-for="h-category"
          >
            <b-form-select
              v-model="productData.category_id"
              :options="categoryOptions"
              class="mt-1"
              size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Link mua hàng"
            label-cols-md="2"
            label-for="h-product-link"
          >
            <b-form-input
              id="h-product-link"
              v-model="productData.product_link"
              placeholder="https://shopee.com/*****"
              :class="{ 'is-invalid' : isInvalid(productData.product_link)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Hiển thị sản phẩm trên trang chủ"
            label-cols-md="2"
            label-for="h-banner"
          >
            <b-form-checkbox
              id="h-banner"
              v-model="productData.is_feature_product"
              name="checkbox-2"
            >
              {{ productData.is_feature_product ? 'Có hiển thị' : 'Không hiển thị'}}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Hình ảnh đại diện"
            label-cols-md="2"
            label-for="h-images"
          >
            <b-form-input
              id="product-avatar"
              v-model="productData.avatar"
              placeholder=""
              rows="5"
              :class="{ 'is-invalid' : isInvalid(productData.avatar)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="productData.avatar">
          <b-form-group
            label="Xem trước hình ảnh"
            label-cols-md="2"
            label-for="h-image-preview "
          >
            <b-img class="preview-image" :src="productData.avatar" v-viewer />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Hình ảnh"
            label-cols-md="2"
            label-for="h-images"
          >
            <b-form-textarea
              id="post-summary"
              v-model="productData.images"
              placeholder=""
              rows="5"
              :class="{ 'is-invalid' : isInvalid(productData.images)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="productData.images">
          <b-form-group
            label="Xem trước hình ảnh"
            label-cols-md="2"
            label-for="h-image-preview "
            img-width="200"
            img-height="200"
          >
            <b-carousel
              id="carousel-example-generic"
              controls
              fades
              indicators
              :interval="3000"
            >
              <b-carousel-slide v-for="(image, index) in productData.images.split('\n')"
                                :key="image + '-' + index"
                                :img-src="image"
                                v-viewer
              />
            </b-carousel>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1"
          type="submit"
          variant="primary"
        >
          Lưu sản phẩm
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BFormSelect,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCarousel,
  BCarouselSlide,
  BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "../../../../@core/components/toastification/ToastificationContent";

export default {
  name: "ProductAdd",
  components: {
    BButton,
    BCard,
    BCarousel,
    BCarouselSlide,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BImg,
    BRow
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isValidate: false,
      files: [],
      productData: {
        name: '',
        description: '',
        price: '',
        product_link: '',
        avatar: '',
        is_feature_product: false,
        unit: 'kg',
        category_id: 1,
        nksx: '{}',
        images: '',
        is_contact_price: false
      },
      categories: []
    }
  },
  methods: {
    isInvalid(val) {
      return this.isValidate && val.length === 0
    },
    removeImage(image, index) {
      this.productData.images.splice(index, 1)
    },
    changeContactPrice (val) {
      if (val) {
        this.productData.price = this.productData.price || '0'
      }
    },
    handleSubmit() {
      this.isValidate = true
      if (
          this.productData.name.length &&
          this.productData.description.length &&
          this.productData.price.length &&
          this.productData.product_link.length &&
          this.productData.avatar.length &&
          this.productData.images.length) {
        this.handleRequest()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Vui lòng nhập đầy đủ thông tin!',
            variant: 'danger',
          },
        })
      }
    },
    async addNKSX(product) {
      const NKSX = {
        avatar: 'nothing',
        author: 'nothing',
        is_research_achievements: false,
        product_url: product.product_link,
        page_id: 'NKSX',
        title: 'Nhật ký sản xuất: ' + product.name,
        category_id: product.category_id,
        summary: JSON.stringify(product),
        content: '[]',
      }

      this.$http.post(`/posts`, NKSX)
          .then(response => {
            this.updateProduct(product, response.data.data)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                icon: 'BellIcon',
                text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                variant: 'danger',
              },
            })
          })
    },
    async updateProduct(product, nksx) {
      nksx.summary = ''
      product.nksx = JSON.stringify(nksx)
      this.$http.put(`/products/${product.id}`, product)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                icon: 'BellIcon',
                text: 'Cập nhật thành công👋',
                variant: 'success',
              },
            })
            this.$router.push('/manage/product/list')
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                icon: 'BellIcon',
                text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                variant: 'danger',
              },
            })
          })
    },
    async handleRequest() {
      this.$http.post(`/products`, this.productData)
        .then(response => {
          this.addNKSX(response.data.data)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    }
  },
  computed: {
    categoryOptions() {
      return this.categories.map((e) => {
        return {
          value: e.id,
          text: e.name
        }
      })
    },
  },
  created() {
    this.$http.get('/search/category?page_id=SP')
      .then(res => {
        this.categories = res.data.data
      })
  }
}
</script>

<style scoped>

</style>
